import React from 'react';
import { Grid, Image, Button, Icon } from 'semantic-ui-react';
import sam from '../img/sam.jpg';

const Founder = () => {
    return (
        <div className="Founder" id="founder">
            <h2 className="HeaderText Primary">Your idea + our experience</h2>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Image src={sam} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <h1 className="FounderTitle">Samuel Corso</h1>
                        <h3 className="FounderSubtitle">Founding Partner</h3>
                        <p className="FounderParagraph">
                            Sam is a software engineer and entrepreneur with 10+ years of experience building web and
                            mobile applications. He has deep fintech expertise and knowledge of leading agile scrum
                            teams.
                        </p>
                        <div className="FlexRow Center FounderLinks">
                            <div className="FlexColumn">
                                <Button icon labelPosition="left" as="a" href="https://samuelcorso.com" target="_blank">
                                    <Icon name="linkify" />
                                    Website
                                </Button>
                            </div>
                        </div>
                        <div className="FlexRow Center FounderLinks">
                            <div className="FlexColumn">
                                <Button
                                    icon
                                    labelPosition="left"
                                    as="a"
                                    href="https://linkedin.com/in/samc621"
                                    target="_blank"
                                >
                                    <Icon name="linkedin" />
                                    LinkedIn
                                </Button>
                            </div>
                        </div>
                        <div className="FlexRow Center FounderLinks">
                            <div className="FlexColumn">
                                <Button
                                    icon
                                    labelPosition="left"
                                    as="a"
                                    href="https://github.com/samc621"
                                    target="_blank"
                                >
                                    <Icon name="github" />
                                    GitHub
                                </Button>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <a href="/#contact" className="ActionButton">
                Let's Collaborate
            </a>
        </div>
    );
};

export default Founder;
