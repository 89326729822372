import Header from '../sections/Header';
import Success from '../sections/Success';
import Solutions from '../sections/Solutions';
import About from '../sections/About';
import Founder from '../sections/Founder';
import Testimonials from '../sections/Testimonials';
import Portfolio from '../sections/Portfolio';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';

function SuccessPage() {
    return (
        <div className="App">
            <Header />
            <Success />
            <About />
            <Founder />
            <Contact />
            <Portfolio />
            <Solutions />
            <Testimonials />
            <Footer />
        </div>
    );
}

export default SuccessPage;
