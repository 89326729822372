import React from 'react';
import Media from 'react-media';
import { slide as Menu } from 'react-burger-menu';
import logo from '../img/logo.png';

const menu = [
    {
        text: 'Home',
        link: '/'
    },
    {
        text: 'About',
        link: '/#about'
    },
    {
        text: 'Founder',
        link: '/#founder'
    },
    {
        text: 'Contact',
        link: '/#contact'
    },
    {
        text: 'Portfolio',
        link: '/#portfolio'
    },
    {
        text: 'Solutions',
        link: '/#solutions'
    },
    {
        text: 'Testimonials',
        link: '/#testimonials'
    },
    {
        text: '+1 347-308-2597',
        link: 'tel:+13473082597'
    }
];

const Header = () => {
    return (
        <div className="Header FlexRow">
            <div className="FlexColumn">
                <a href="/">
                    <img src={logo} alt="TechSuite Logo" height={30} style={{ marginTop: '20px' }}></img>
                </a>
            </div>
            <div className="FlexColumn NavFlex">
                <Media
                    queries={{
                        mobile: '(max-width: 1200px)'
                    }}
                >
                    {(matches) =>
                        matches.mobile ? (
                            <Menu right>
                                {menu.map((menuItem, index) => {
                                    return (
                                        <a key={index} href={menuItem.link} target={menuItem.target || ''}>
                                            {menuItem.text}
                                        </a>
                                    );
                                })}
                            </Menu>
                        ) : (
                            <ul className="NavList">
                                {menu.map((menuItem, index) => {
                                    return (
                                        <a
                                            key={index}
                                            className="NavLink"
                                            href={menuItem.link}
                                            target={menuItem.target || ''}
                                        >
                                            {menuItem.text}
                                        </a>
                                    );
                                })}
                            </ul>
                        )
                    }
                </Media>
            </div>
        </div>
    );
};

export default Header;
