import React from 'react';

const Success = () => {
    return (
        <header className="Home" id="home">
            <h1 className="BannerTitle">Success!</h1>
            <h4 className="BannerSubtitle">
                You've booked a call with TechSuite. We look forward to meeting with you soon.
            </h4>
            <a href="/#contact" className="ActionButton">
                Let's Get Started
            </a>
        </header>
    );
};

export default Success;
