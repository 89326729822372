import React from 'react';
import { ContactForm } from './Contact';

const Fintech = () => {
    return (
        <header className="Fintech" id="home">
            <div className="FlexRow">
                <div className="FlexColumn">
                    <h2 className="HeaderText White">Hire Fintech and Blockchain Experts</h2>

                    <h3 className="SubheaderText White">
                        TechSuite is helping innovative companies pave the way for the future of finance.
                        <br />
                        Our experts come with years of experience across different verticals such as:
                    </h3>
                    <ul className="FintechList">
                        <li className="FintechItems">Lending</li>
                        <li className="FintechItems">Trading</li>
                        <li className="FintechItems">PE/VC</li>
                        <li className="FintechItems">Personal Finance</li>
                        <li className="FintechItems">Payments</li>
                        <li className="FintechItems">Insurance</li>
                    </ul>
                    <h3 className="SubheaderText White">
                        We also aren't new to Web3. TechSuite has been enabling companies to harness the power of
                        blockchain technology since 2017 with our specialized expertise in developing robust and secure
                        smart contracts on Ethereum, Hyperledger, and Solana.
                    </h3>
                    <h3 className="SubheaderText White">
                        Whether you're here to transform the financial system, or disintermiate it, we're here to help
                        you make it happen.
                    </h3>
                </div>
                <div className="FlexColumn">
                    <h2 className="HeaderText White">Tell us more about your project</h2>
                    <ContactForm />
                </div>
            </div>
        </header>
    );
};

export default Fintech;
