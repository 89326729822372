import React from 'react';

const About = () => {
    return (
        <div className="About" id="about">
            <h2 className="HeaderText White">Your entrepreneurial guide</h2>
            <div className="AboutParagraph">
                <p>
                    TechSuite’s founder, Samuel Corso, envisioned TechSuite as an all-encompassing “suite” of tools and
                    services for startups looking to bring new products to market. His passion for startups stems from
                    his own entrepreneurial spirit and years of experience as a software engineer working with startups.
                </p>
                <p>
                    TechSuite recognizes that you have different challenges and objectives, whether it be figuring out
                    how to <b>scope an MVP</b>, choosing the <b>right technology stack</b>, finding and retaining{' '}
                    <b>great talent</b>, <b>managing technical projects</b>, overcoming <b>technical debt</b>, and more.
                </p>
                <p>
                    Sam meets <i>you</i> where you are in your journey, and works with you on an interpersonal level to
                    bring your ideas to life.
                </p>
            </div>
            <a href="/#contact" className="ActionButton">
                Talk to Sam
            </a>
        </div>
    );
};

export default About;
