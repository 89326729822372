import React, { useState } from 'react';
import { Button, Icon, Item, Image, Label, Modal } from 'semantic-ui-react';
import fleeting from '../img/fleeting.jpeg';
import fleeting2019 from '../img/fleeting2019.jpg';
import piflive from '../img/piflive.jpeg';
import piflive2019 from '../img/piflive2019.jpg';
import mentat from '../img/mentat.png';
import heydog from '../img/heydog.jpeg';
import bazarik from '../img/bazarik.jpeg';
import petly from '../img/petly.jpeg';
import venu from '../img/venu.jpeg';
import beep from '../img/beep.jpeg';
import eatsy from '../img/eatsy.png';
import beanthere from '../img/beanthere.png';

const projects = [
    {
        title: 'Fleeting',
        description: 'Fleeting is a driver-first platform that connects CDL drivers with on-demand trucking jobs.',
        category: 'Logistics, Marketplace',
        technologies: [
            {
                name: 'React.js',
                icon: 'globe'
            },
            {
                name: 'React Native',
                icon: 'mobile'
            },
            { name: 'Node.js', icon: 'server' },
            { name: 'DynamoDB', icon: 'database' }
        ],
        image: fleeting,
        story: (
            <>
                <Image size="large" centered src={fleeting2019}></Image>
                <div className="PortfolioStory">
                    <p>
                        Sam met the founder of{' '}
                        <a href="https://www.fleeting.us/" target="_blank" rel="noreferrer">
                            Fleeting
                        </a>
                        , Pierre Laguere, at a networking event in NYC in early 2019. On the surface, Fleeting was
                        another “Uber for trucking,” but Pierre had something which many other founders and operators in
                        the space lacked. His years of experience as a trucker, and as the owner of a trucking company
                        gave him the desire to bring a carrier-centric solution to the market, while the industry mainly
                        focused on cutting costs for shippers. He desired not only to improve the lives of existing
                        drivers, but envisioned Fleeting as a conduit for millions interested in a viable new career as
                        a trucker, or even an owner-operator.
                    </p>
                    <p>
                        In just under three months, from April to June of 2019, TechSuite brought Fleeting’s first
                        product to the market, comprised of iOS and Android-compatible mobile apps as well as
                        administrative systems for carriers and shippers. Laguerre went on in{' '}
                        <a href="https://republic.com/fleeting" target="_blank" rel="noreferrer">
                            2020
                        </a>{' '}
                        to become the first Black man to raise the full $1.07M limit in equity crowdfunding, backed by
                        over 4,500 investors. In{' '}
                        <a
                            href="https://www.prnewswire.com/news-releases/fleeting-a-black-owned-commercial-trucking-and-fleet-management-company-receives-500-000-investment-from-nba-star-kyrie-irving-and-new-fund-lockstep-ventures-301287780.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            2021
                        </a>
                        , the company received an additional $500k investment from KAI 11 Consulting (founded by NBA
                        star Kyrie Irving) and Lockstep Ventures.
                    </p>
                </div>
            </>
        )
    },
    {
        title: 'Pay It Forward (PiF) Live',
        description: 'PiF Live is a video-sharing platform that incentivizes users to perform charitable deeds.',
        category: 'Social Network',
        technologies: [
            {
                name: 'Swift',
                icon: 'mobile'
            },
            { name: 'PHP', icon: 'server' },
            { name: 'MySQL', icon: 'database' }
        ],
        image: piflive,
        story: (
            <>
                <Image size="large" centered src={piflive2019}></Image>
                <div className="PortfolioStory">
                    <p>
                        PiF Live founder Shari Smith-Jackson took a train from Philadelphia to meet with TechSuite at
                        our NYC office in 2017. She presented a social app for recording and documenting acts of
                        kindness and service projects, but with a twist. The concept was inspired by her son’s years of
                        community volunteering efforts. The first challenge was how to engage users and encourage them
                        to participate in a way that made volunteering fun, bonding, and even competitive. The next
                        challenge was then how to monetize the concept in a novel way.
                    </p>
                    <p>
                        Through the use of video, in a format similar to a Snapchat story, users upload content and rate
                        that of others in order to earn points. Charitable organizations can monitor volunteer hours in
                        a fully-digitized format. TechSuite delivered several new versions of PiF Live through 2018 and
                        2019. In April 2019, PiF Live took top prize in the social impact category at Temple Fox School
                        of Business’s business plan competition. Following the victory, TechSuite helped the app to
                        scale its video streaming infrastructure as its user base grew. In July 2019, Smith-Jackson’s
                        inspiring story was{' '}
                        <a
                            href="https://www.inquirer.com/business/pay-it-forward-live-apps-volunteering-20190728.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            featured
                        </a>{' '}
                        in the Philadelphia Inquirer, including remarks from Sam who was also interviewed for the
                        article.
                    </p>
                </div>
            </>
        )
    },
    {
        title: 'Mentat',
        description:
            'Mentat is a decentralized work platform for applications and individuals to source human intelligence on an open market, on-demand.',
        category: 'Blockchain, Marketplace',
        technologies: [
            {
                name: 'React.js',
                icon: 'globe'
            },
            {
                name: 'web3.js',
                icon: 'globe'
            },
            { name: 'Solidity', icon: 'chain' },
            { name: 'Truffle', icon: 'chain' },
            { name: 'Ganache', icon: 'chain' },
            { name: 'Node.js', icon: 'server' },
            { name: 'MongoDB', icon: 'database' }
        ],
        image: mentat
    },
    {
        title: 'HeyDog',
        description:
            'HeyDog is a dog-walker and pet-sitter marketplace, built to emulate the success of “Rover” for the Norwegian market.',
        category: 'Marketplace',
        technologies: [
            {
                name: 'Ruby on Rails',
                icon: 'globe'
            },
            { name: 'SQLite', icon: 'database' }
        ],
        image: heydog
    },
    {
        title: 'Bazarik',
        description:
            'Bazarik is an multi-vendor marketplace and Alibaba competitor, built specifically for the Middle Eastern market.',
        category: 'Marketplace',
        technologies: [
            {
                name: 'Angular JS',
                icon: 'globe'
            },
            {
                name: 'PHP',
                icon: 'server'
            },
            { name: 'MySQL', icon: 'database' }
        ],
        image: bazarik
    },
    {
        title: 'Petly',
        description:
            'Petly is an IoT-powered doghouse that keeps your canine engaged while you shop and enhances customer experience for brick and mortar enterprises.',
        category: 'IoT',
        technologies: [
            {
                name: 'React Native',
                icon: 'mobile'
            },
            {
                name: 'Node.js',
                icon: 'server'
            },
            {
                name: 'Express JS',
                icon: 'server'
            },
            { name: 'PostgreSQL', icon: 'database' }
        ],
        image: petly
    },
    {
        title: 'VenU',
        description:
            'VenU is Weedmaps for bars, clubs, and lounges. Users can search for venues, view events, purchase tickets, and more.',
        category: 'Entertainment',
        technologies: [
            {
                name: 'React Native',
                icon: 'mobile'
            },
            {
                name: 'React.js',
                icon: 'globe'
            },
            {
                name: 'Node.js',
                icon: 'server'
            },
            {
                name: 'Express JS',
                icon: 'server'
            },
            { name: 'PostgreSQL', icon: 'database' }
        ],
        image: venu
    },
    {
        title: 'BeeP',
        description:
            'BeeP allows users to chat with and send money to each other, shop at stores and earn rewards, and pay their bills, all in one place.',
        category: 'FinTech',
        technologies: [
            {
                name: 'React Native',
                icon: 'mobile'
            },
            {
                name: 'React.js',
                icon: 'globe'
            },
            {
                name: 'Node.js',
                icon: 'server'
            },
            {
                name: 'Express JS',
                icon: 'server'
            },
            { name: 'MongoDB', icon: 'database' }
        ],
        image: beep
    },
    {
        title: 'Eatsy',
        description: 'Eatsy is a food delivery platform focused on college campuses.',
        category: 'Logistics',
        technologies: [
            {
                name: 'Swift',
                icon: 'mobile'
            },
            {
                name: 'Angular JS',
                icon: 'globe'
            },
            {
                name: 'PHP',
                icon: 'server'
            },
            { name: 'MySQL', icon: 'database' }
        ],
        image: eatsy
    },
    {
        title: 'BeanThere',
        description: 'BeanThere is a social network for "foodies.',
        category: 'Marketplace',
        technologies: [
            {
                name: 'Swift',
                icon: 'mobile'
            },
            {
                name: 'Node.js',
                icon: 'server'
            },
            {
                name: 'Express JS',
                icon: 'server'
            },
            { name: 'MongoDB', icon: 'database' }
        ],
        image: beanthere
    }
];

const Portfolio = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);

    const openStoryInModal = (story) => {
        setModalOpen(true);
        setSelectedStory(story);
    };

    return (
        <>
            <Modal onClose={() => setModalOpen(false)} onOpen={() => setModalOpen(true)} open={modalOpen}>
                <Modal.Header>Featured Story</Modal.Header>
                <Modal.Content>
                    <Modal.Description>{selectedStory}</Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Done"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => setModalOpen(false)}
                        className="AccentBackground White"
                    />
                </Modal.Actions>
            </Modal>

            <div className="Portfolio" id="portfolio">
                <h2 className="HeaderText Primary">You're our next successful launch</h2>
                <Item.Group divided>
                    {projects.map((project, i) => {
                        return (
                            <Item key={i}>
                                <Item.Image src={project.image} size={'large'} />
                                <Item.Content>
                                    <Item.Header>{project.title}</Item.Header>
                                    <Item.Meta>
                                        <span>{project.category}</span>
                                    </Item.Meta>
                                    <Item.Description>{project.description}</Item.Description>
                                    <Item.Extra>
                                        {project.technologies.map((technology, ii) => {
                                            return <Label key={ii} icon={technology.icon} content={technology.name} />;
                                        })}
                                    </Item.Extra>
                                    {project.story && (
                                        <Item.Extra>
                                            <Button
                                                className="AccentBackground White"
                                                floated="right"
                                                onClick={() => openStoryInModal(project.story)}
                                            >
                                                Read the story
                                                <Icon name="right chevron" />
                                            </Button>
                                        </Item.Extra>
                                    )}
                                </Item.Content>
                            </Item>
                        );
                    })}
                </Item.Group>
                <a href="/#contact" className="ActionButton">
                    I'm In
                </a>
            </div>
        </>
    );
};

export default Portfolio;
