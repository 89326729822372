import React from 'react';

const Home = () => {
    return (
        <header className="Home" id="home">
            <h1 className="BannerTitle">
                Not Just <span className="Accent">App Development</span>
            </h1>
            <h4 className="BannerSubtitle">
                TechSuite has been empowering startup founders to bring their ideas to market since 2016, and gives them
                the necessary tools to make it happen.
            </h4>
            <div className="HomeButtonContainer FlexRow">
                <a href="/#contact" className="ActionButton">
                    Let's Get Started
                </a>
                <a
                    href="https://calendly.com/samc621/techsuite-consultation"
                    target="_blank"
                    rel="noreferrer"
                    className="ActionButton"
                >
                    Schedule A Call
                </a>
            </div>
        </header>
    );
};

export default Home;
