import Header from '../sections/Header';
import Fintech from '../sections/Fintech';
import Founder from '../sections/Founder';
import Solutions from '../sections/Solutions';
import Testimonials from '../sections/Testimonials';
import Footer from '../sections/Footer';

function FintechPage() {
    return (
        <div className="App">
            <Header />
            <Fintech />
            <Founder />
            <Solutions />
            <Testimonials />
            <Footer />
        </div>
    );
}

export default FintechPage;
