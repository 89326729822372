import React from 'react';

const services = [
    'App Design & Prototyping',
    'Web App Development',
    'Mobile App Development',
    'Blockchain Development',
    'Quality Assurance Testing'
];
const stacks = [
    'Javascript (React, React Native, Angular, Node, Express)',
    'Ethereum (Solidity, web3.js, Truffle, Ganache)',
    'iOS (Swift)',
    'Android (Java, Kotlin)',
    'Python'
];

const Solutions = () => {
    return (
        <div className="Solutions" id="solutions">
            <h2 className="HeaderText White">Your all-in-one shop</h2>
            <div className="FlexRow SolutionsLists">
                <div className="FlexColumn">
                    <h2 className="HeaderText Accent">Services</h2>
                    <ul className="SolutionsList">
                        {services.map((service, index) => (
                            <li key={index} className="SolutionsItems">
                                {service}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="FlexColumn">
                    <h2 className="HeaderText Accent">Stack</h2>
                    <ul className="SolutionsList">
                        {stacks.map((stack, index) => (
                            <li key={index} className="SolutionsItems">
                                {stack}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <a href="/#contact" className="ActionButton">
                Let's Build
            </a>
        </div>
    );
};

export default Solutions;
