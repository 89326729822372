import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import ClientsApp from './clients/App';
// import EstimatorApp from './estimator/App';
import SiteApp from './site/App';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/*" element={<SiteApp />} />
                {/* <Route path="/clients/*" element={<ClientsApp />} />
                <Route path="/estimator/*" element={<EstimatorApp />} /> */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;
