import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home';
import SucessPage from './pages/Success';
import FintechPage from './pages/Fintech';

function App() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/success" element={<SucessPage />} />
            <Route path="/fintech" element={<FintechPage />} />
        </Routes>
    );
}

export default App;
