import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import chase from '../img/chase.jpeg';
import ushir from '../img/ushir.jpeg';
import noel from '../img/noel.jpeg';

const clients = [
    {
        name: 'Chase Hughes',
        title: 'Equity Up LLC',
        imgSrc: chase,
        bio: `“I've worked with Sam and his team over the past three years and have been extremely impressed by his passion, problem solving ability, and rare management skills.”`,
        linkedin: 'https://www.linkedin.com/in/chasehughes'
    },
    {
        name: 'Ushir Shah',
        title: 'Namu Inc.',
        imgSrc: ushir,
        bio: `“… Not only are Sam's intellect and technical proficiency top notch, but his kindness, integrity, and work ethic is what sets him apart from many others. ...His skill in leading off-shore and on-shore engineering teams made it feel seamless. … [We] were working in the trenches to bring our fintech product to life. I would highly recommend Sam to any teams looking for an A-player.”`,
        linkedin: 'https://www.linkedin.com/in/ushir'
    },
    {
        name: 'Noel Abebe',
        title: 'Eatsy Inc.',
        imgSrc: noel,
        bio: ` “[Sam] always goes the extra mile [...] and is an excellent advisor and consultant."`,
        linkedin: 'https://www.linkedin.com/in/noeldaniely'
    }
];

const Testimonials = () => {
    return (
        <div className="Testimonials" id="testimonials">
            <h2 className="HeaderText Primary">Here’s what founders say about us</h2>
            <div className="TestimonialCards">
                <Card.Group centered stackable itemsPerRow={3} className="TestimonialCardsGroup">
                    {clients.map((member, index) => {
                        return (
                            <Card key={index} centered>
                                <Image src={member.imgSrc} wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header style={{ margin: 0 }}>{member.name}</Card.Header>
                                    <Card.Meta>{member.title}</Card.Meta>
                                    <Card.Description>{member.bio}</Card.Description>
                                </Card.Content>
                                {member.linkedin && (
                                    <Card.Content extra>
                                        <a href={member.linkedin} target="_blank" rel="noreferrer">
                                            <Icon name="linkedin" />
                                            LinkedIn
                                        </a>
                                    </Card.Content>
                                )}
                            </Card>
                        );
                    })}
                </Card.Group>
            </div>
            <a href="/#contact" className="ActionButton">
                Let's Talk More
            </a>
        </div>
    );
};

export default Testimonials;
