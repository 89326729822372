import React, { useState } from 'react';
import { Form, Message } from 'semantic-ui-react';
import sendEmail from '../helpers/email';

export const ContactForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [budget, setBudget] = useState('');
    const [reason, setReason] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const budgetOptions = [
        {
            key: 'Low',
            text: '$0-10k',
            value: '$0-10k'
        },
        {
            key: 'Medium',
            text: '$10-25k',
            value: '$10-25k'
        },
        {
            key: 'High',
            text: '$25-50k',
            value: '$25-50k'
        },
        {
            key: 'Highest',
            text: '$50k+',
            value: '$50k+'
        }
    ];

    const submit = async (e) => {
        e.preventDefault();
        setShowError(false);
        setShowSuccess(false);

        if (!firstName || !lastName || !email || !phone || !company || !title || !budget || !reason) {
            setShowError(true);
            setErrorMessage('Please complete all required fields.');
            return;
        }

        try {
            const { status } = await sendEmail({ firstName, lastName, email, phone, company, title, budget, reason });
            if (status === 200) {
                setShowSuccess(true);
                setSuccessMessage('Email sent successfully!');
            } else {
                throw new Error('Something went wrong.');
            }
        } catch (err) {
            setShowError(true);
            setErrorMessage(err.response?.data?.message ?? err.message);
        }
    };

    return (
        <Form className="ContactForm" error={showError} success={showSuccess}>
            <div className="FlexRow">
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            First Name <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Form.Field>
                </div>
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            Last Name <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Form.Field>
                </div>
            </div>
            <div className="FlexRow">
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            Email <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Field>
                </div>
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            Phone <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="tel"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Field>
                </div>
            </div>
            <div className="FlexRow">
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            Company <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="Company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </Form.Field>
                </div>
                <div className="FlexColumn">
                    <Form.Field className="ContactField">
                        <label className="ContactLabel White Left">
                            Title <span className="Required">*</span>
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Field>
                </div>
            </div>
            <Form.Field className="ContactField">
                <label className="ContactLabel White Left">
                    Budget <span className="Required">*</span>
                </label>
                <Form.Select
                    fluid
                    placeholder="Budget"
                    options={budgetOptions}
                    value={budget}
                    onChange={(e, data) => setBudget(data.value)}
                />
            </Form.Field>
            <Form.Field className="ContactField">
                <label className="ContactLabel White Left">
                    How can we help? <span className="Required">*</span>
                </label>
                <Form.TextArea
                    placeholder="Tell us what we can do for your company..."
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </Form.Field>
            <Message
                error
                header="Contact form submission error"
                content={errorMessage}
                className="ContactFormMessage"
            />
            <Message success header="Contact form submitted" content={successMessage} className="ContactFormMessage" />
            <Form.Button className="ContactSubmit" onClick={submit}>
                Submit
            </Form.Button>
        </Form>
    );
};

const Contact = () => {
    return (
        <div className="Contact" id="contact">
            <h2 className="HeaderText White">Tell us more about your project</h2>
            <ContactForm />
        </div>
    );
};

export default Contact;
